
<template>
  <div>
    <h1>test page</h1>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {};
  },
  created() {
      console.log('test created')
    this.getAddress();
  },
  methods: {
    async getAddress() {
      await this.$dapp.waiting();
      console.log("TestPage获取到地址：", this.$dapp.address);
    },
  },
};
</script>